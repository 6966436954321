import * as t from 'io-ts'

import { StoreDecoder } from './store'
import { Product, ProductDecoder, ProductPriceDecoder } from './product'
import { AnalyticsCodec } from './analytics'
import { VMInitParamsDecoder } from './vmInit'
import { TransitionFeatureDecoder } from './transition'
import { VtoPageDecoder } from './experience'
import { BipaFormData } from '@luxottica/virtual-mirror'
import { AlternativeDecisionCodec, isOptional } from './alternateDecision'
import { LensItemDecoder } from './lens'
import { CurrencySideCodec, PriceLineOrderCodec, parseCurrencySide, parsePriceOrder } from './price'
import { LinkDecoder } from './links'

export const ConfigCodec = t.intersection(
  [
    t.type(
      {
        /**
         * The CSS selector of the element in which the widget/qr-code will be printed
         */
        selector: t.string,

        /**
         * Array of product for virtual-mirror
         */
        products: t.array(ProductDecoder),

        /**
         * Store data for virtual-mirror
         */
        store: StoreDecoder,

        /**
         * Style value (use store name prefix ex: sgh, rb etc)
         */
        style: t.string,

        /**
         * Params for Virtual Mirror initial
         */
        vmInit: VMInitParamsDecoder,

        /** Alternative decision path modal copy */
        alternativeDecisionPath: isOptional(AlternativeDecisionCodec, true),

        onAddToBag: isOptional(t.Function),

        /**
         * @optional
         * parameter to configure where the price put after the other
         *
         * @default '["fullPrice", "newPrice"] fullPrice comes first before the newPrice
         */
        priceLineOrder: parsePriceOrder(PriceLineOrderCodec),

        /**
         * @optional
         * parameter to configure where to put the currency in the price
         * 'LEFT' = currency symbol will be put on before the price
         * 'RIGHT' = currency symbol will be put after the price
         *
         * @default 'LEFT'
         */
        currencySide: parseCurrencySide(CurrencySideCodec),
      },
      'IntersectConfig01'
    ),
    // Optional
    t.partial(
      {
        /**
         * The locale of the widget (if not present, there is a fallback on en-US)
         *
         * @default: en-US
         */
        locale: t.string,

        /**
         * Array of product prices for virtual-mirror contains upc/current/previos/currency
         */
        prices: t.array(ProductPriceDecoder),

        /**
         * FromStore store params tells us vm opened from store
         */
        fromStore: t.boolean,

        /**
         * Analytics data to track
         */
        analytics: AnalyticsCodec,

        /**
         * Transition feature. Consists on\off and default active panel name
         */
        transitionFeature: TransitionFeatureDecoder,

        /**
         * On\off Try them on feature
         */
        isTryOnEnabled: t.boolean,

        /**
         * On\off Monobrand activation
         */
        isMonobrand: t.boolean,

        /**
         * On\off Use Cart icon instead of text in Add to cart CTA
         */
        useCartIcon: t.boolean,

        /**
         * On\off Picture Mode feature
         */
        isPictureModeEnabled: t.boolean,

        /**
         * On\off Upload Picture feature
         */
        isUploadPictureEnabled: t.boolean,

        /**
         * On\off Take Picture feature
         */
        isTakePictureEnabled: t.boolean,

        /**
         * On\off Video Mode feature
         */
        isVideoModeEnabled: t.boolean,

        /**
         * On\off QR Code feature
         */
        isQrCodeEnabled: t.boolean,

        /**
         * On\off Take screenshot feature
         */
        isTakeScreenshotEnabled: t.boolean,

        /** BipaPrefill params */
        bipaPrefill: t.partial({
          firstName: t.string,
          lastName: t.string,
          email: t.string,
        }),

        /** Link to the external source of terms and conditions. Link must start with http(s)://.
         * @example https://google.com
         */
        termsAndConditions: t.string,

        /**
         * Array of UPC, that currently in the wishlist
         */
        wishlistedUpcs: t.array(t.string),

        /**
         * "Add To Cart" CTA label, if it has a value then it will override the current value
         */
        addToCartText: t.string,

        /**
         * On/off polaroid "save image" feature
         */
        disableSavePolaroid: t.boolean,

        /**
         * Personalize of landing page
         * @type {Experience}: possible values are 'live-try-on' | 'picture-mode' | 'video-mode'
         * @default: live-try-on
         */
        experience: VtoPageDecoder,

        polaroid: t.partial({
          linkA: LinkDecoder,
          linkB: LinkDecoder,
        }),

        /**
         * this is the list of swatches that will be shown in the swatches list
         */
        lensesList: t.array(LensItemDecoder),

        /**
         * Allow the integrator to use Cart Icon instead of Arrow Icon for RX Configurator
         */
        disableArrowIcon: t.boolean,
        /**
         * Allow the integrator to use Arrow Icon instead of Cart Icon in footer
         */
        disableCartIcon: t.boolean,
        /**
         * Allow the integrator to use Download Icon instead of Share Icon in footer
         */
        useDownloadIcon: t.boolean,
        /**
         * Parameter to restart the menu animation once every VMMV loads
         */
        restartMenuAnimation: t.boolean,

        onError: t.Function,
        onAddPrescriptionLenses: t.Function,
        onToggleWishlist: t.Function,
        onClose: t.Function,
        onShare: t.Function,
        onPrivacyReject: t.Function,
        onPrivacyAgree: t.Function,
        onPrivacyFail: t.Function,
        onTermsAndConditionsClick: t.Function,
        onAlternativeDecisionPath: t.Function,
        onLensChange: t.Function,
      },
      'IntersectConfig02'
    ),
  ],
  'Config'
)

type ConfigCallbacks = {
  /** Callback to add a product to the cart */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void

  /** Callback to add a product to the cart */
  onAddToBag?: (product: Product) => void

  /** Callback to add prescription lenses */
  onAddPrescriptionLenses?: (product: Product) => void

  /** Callback to toggle wishlist status of product */
  onToggleWishlist?: (product: Product) => Promise<void>

  /** Callback executed on close click action */
  onClose?: () => void

  /** Callback executed on share click btn, apply image data url in base64 format and product
   * then intergator decide what to return url for img or url for PDP or ShareData Object: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#parameters */
  onShare?: (imageDataUrl: string, product: Product) => Promise<string | ShareData>

  /** Callback executed after rejecting privacy policy */
  onPrivacyReject?: () => void

  /** Callback executed after agreeing privacy policy */
  onPrivacyAgree?: (bipaFormData?: BipaFormData) => void

  /** Callback executed after privacy policy fail */
  onPrivacyFail?: (error: Error) => void

  /** Callback when user clicks terms and condition */
  onTermsAndConditionsClick?: () => void

  /** Alternative callback that will display a modal
   *  with two CTAs, one mapped to onAddToBag()
   *  the other mapped to onAlternativeAddToBag()
   */
  onAlternativeDecisionPath?: (product: Product) => void

  /** Callback when user changes Transitions Color */
  onLensChange?: (id: string) => void
}

export type Config = t.TypeOf<typeof ConfigCodec> & ConfigCallbacks
