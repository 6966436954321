import config from '../config'

export const warmUp = async (key?: string) => {
  const { VirtualMirror } = await import('@luxottica/virtual-mirror')

  const isSupported = await VirtualMirror.isBrowserSupported()

  if (isSupported) {
    VirtualMirror.warmUp(config.vmEnv as Parameters<typeof VirtualMirror.warmUp>[0], key)

    import(/* webpackPrefetch: true */ '@luxottica/frame-advisor-capture')
    import(/* webpackPrefetch: true */ '@luxottica/vto-core')
    import(/* webpackPrefetch: true */ '@luxottica/virtual-mirror')

    return Promise.resolve()
  } else {
    // eslint-disable-next-line no-console
    return Promise.reject('Virtual Mirror was not supported on the current browser/device')
  }
}
