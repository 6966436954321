export const reset: (resetPrivacyPolicy?: boolean) => void = async (resetPrivacyPolicy = true) => {
  // use dynamic imports so it will not bundle this into the root JS
  if (resetPrivacyPolicy) {
    const { FrameAdvisorCapture } = await import('@luxottica/frame-advisor-capture')
    const { VirtualMirror } = await import('@luxottica/virtual-mirror')
    VirtualMirror.resetBipaState()
    FrameAdvisorCapture.resetBipaState()
  }

  const { clearPictureVideoIds } = await import('@utils/sessionStorage')
  clearPictureVideoIds()
}
