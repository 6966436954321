import * as t from 'io-ts'

export const LensItemDecoder = t.type(
  {
    name: t.string,
    colorName: t.string,
    swatchImage: t.string,
    lensIdentifier: t.string,
  },
  'LensItem'
)

export type LensItem = t.TypeOf<typeof LensItemDecoder>
